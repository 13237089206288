import { BehaviorSubject } from "rxjs";

export const poses = [
  "initial",
  "excited",
  "laughing",
  "shy",
  "surprised",
  "thinking",
];
class CharacterBloc {
  constructor() {
    this.subject = new BehaviorSubject("shy");
  }

  changePose = (newPose) => {
    this.subject.next(newPose);
  };

  error = () => {
    this.subject.error("An error occured");
  };

  getSubject = () => {
    return this.subject;
  };
}
export default CharacterBloc;
