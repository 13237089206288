import { colors } from "../styles";
import { isMobile } from "../util";
import BlocBuilder from "./BlocBuilder";
import { audioBloc } from "../global.js";
import { ReactComponent as Mute } from "../images/mute.svg";
import { ReactComponent as NotMute } from "../images/notMute.svg";

export default function AudioPlayer(props) {
  return (
    <BlocBuilder
      subject={audioBloc.subject}
      builder={() => (
        <button
          onClick={() => {
            audioBloc.togglePlay();
          }}
          style={{ color: props.color }}
        >
          {!audioBloc.subject.value ? (
            <Mute style={{ height: 50, width: 50 }} />
          ) : (
            <NotMute style={{ height: 50, width: 50 }} />
          )}
        </button>
      )}
    />
  );
}
