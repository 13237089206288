import React, { useState } from "react";
import excited from "../images/yuri/excited.webp";
import laughing from "../images/yuri/laughing.webp";
import shy from "../images/yuri/shy.webp";
import surprised from "../images/yuri/surprised.webp";
import thinking from "../images/yuri/thinking.webp";
import BlocBuilder from "./BlocBuilder";
import { yuriBloc } from "../global.js";
import { isMobile } from "../util";
class Yuri extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <BlocBuilder
        subject={yuriBloc.subject}
        builder={(snapshot) => renderCharacter(snapshot.data)}
      />
    );
  }
}

function renderCharacter(pose) {
  switch (pose) {
    case "excited":
      return (
        <div style={{ ...st_character, backgroundImage: `url(${excited})` }} />
      );
    case "laughing":
      return (
        <div style={{ ...st_character, backgroundImage: `url(${laughing})` }} />
      );
    case "shy":
      return (
        <div style={{ ...st_character, backgroundImage: `url(${shy})` }} />
      );
    case "surprised":
      return (
        <div
          style={{ ...st_character, backgroundImage: `url(${surprised})` }}
        />
      );
    case "thinking":
      return (
        <div style={{ ...st_character, backgroundImage: `url(${thinking})` }} />
      );

    default:
      console.log(
        `WARNING: pose ${pose} not recognized, using the default pose`
      );
      return (
        <div style={{ ...st_character, backgroundImage: `url(${shy})` }} />
      );
  }
}

const st_character = {
  backgroundPosition: isMobile() ? "top" : "bottom",
  backgroundSize: isMobile() ? "cover" : "contain",
  backgroundRepeat: "no-repeat",
  minWidth: 300,
  width: "100%",
  height: "100%",
  maxWidth: 350,
  marginLeft: "5vw",
  marginRight: "5vw",
};

export default Yuri;
