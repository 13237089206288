export const colors = {
  primary: "#00C0EA",
  primaryDark: "#0097B8",
  secondary: "#F356D5",
  secondaryDark: "#F5ACE7",
  secondaryLight: "#FFD9F8",
  background: "#FFF6FE",
  black: "#000000",
  white: "#FFFFFF",
};
