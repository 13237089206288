import React, { useState } from "react";
import Yuri from "./Yuri";
import TextMessages from "./TextMessages";
import classroom from "../images/classroom.jpg";
import classroomMobile from "../images/classroom-mobile.jpg";
import { isMobile } from "../util";
import AudioPlayer from "./AudioPlayer";
import { colors } from "../styles";
function Scene() {
  return (
    <div style={scene}>
      {!isMobile() ? (
        <div
          style={{
            paddingLeft: 20,
            paddingTop: 20,
            position: "absolute",
            zIndex: 10,
          }}
        >
          <AudioPlayer color={colors.white} />
        </div>
      ) : (
        <div />
      )}
      <div
        style={{
          height: "100%",
          position: "relative",
          left: 0,
          zIndex: 1,
          width: isMobile() ? 0 : "40%",
        }}
      >
        <Yuri />
      </div>
      <div
        style={{
          height: "100%",
          position: isMobile() ? "absolute" : "relative",
          right: 0,
          width: "100%",
          zIndex: 2,
        }}
      >
        <TextMessages />
      </div>

      {/* <div
        style={{
          height: "100%",
          position: "relative",
          width: isMobile() ? 0 : "40%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      ></div> */}
    </div>
  );
}
export default Scene;
const scene = {
  backgroundImage: isMobile() ? `url(${classroomMobile})` : `url(${classroom})`,
  backgroundPosition: isMobile() ? "top" : "bottom",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  maxHeight: "auto",
  flex: 1,
  display: "flex",
  flexDirection: "row",
  borderRadius: isMobile() ? 0 : 5,
  position: "relative",
};
