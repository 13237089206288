import { BehaviorSubject } from "rxjs";
import { Howl } from "howler";
import theme from "../assets/theme.mp3";
class AudioBloc {
  constructor() {
    this.subject = new BehaviorSubject(false);
    this.audio = new Howl({
      src: [theme],
      volume: 0.05,
      loop: true,
    });
    this.clickedBefore = false;
  }
  play = () => {
    this.subject.next(true);
    this.audio.play();
    this.clickedBefore = true;
  };
  pause = () => {
    this.subject.next(false);
    this.audio.pause();
    this.clickedBefore = true;
  };
  togglePlay = () => {
    if (this.subject.value) {
      this.pause();
    } else {
      this.play();
    }
  };
}
export default AudioBloc;
