import { BehaviorSubject } from "rxjs";
import { sleep } from "../util";
import { yuriBloc } from "../global";
import { poses } from "../util.js";
const initialTextData = [
  {
    isUser: false,
    messageText: "Welcome to the Literature Club. It's a pleasure meeting you.",
  },
];
class ChatBloc {
  constructor() {
    this.subject = new BehaviorSubject(initialTextData);
    this.isLoading = false;
    this.poseIndex = 0;
  }
  sendMessage = async (messageText) => {
    this.addMessage({ isUser: true, messageText: messageText });
    //TODO: add API endpoint
    this.isLoading = true;
    await sleep(1000);
    const reply = {
      pose: "thinking",
      message: {
        isUser: false,
        messageText:
          "This is a filler reply. I'll add real replies as soon as the back-end is done.",
      },
    };
    this.isLoading = false;
    this.poseIndex = (this.poseIndex + 1) % poses.length;
    yuriBloc.changePose(poses[this.poseIndex]);
    console.log("Pose changed to " + reply.pose);
    this.addMessage(reply.message);
  };

  addMessage = (message) => {
    const curVal = this.subject.value;
    curVal.push(message);
    this.subject.next(curVal);
  };
  error = () => {
    this.subject.error("An error occured");
  };

  getSubject = () => {
    return this.subject;
  };
}
export default ChatBloc;
