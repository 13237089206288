import React from "react";
import { colors } from "../styles.js";
import { chatBloc } from "../global.js";
import BlocBuilder from "./BlocBuilder.js";
import { isMobile } from "../util.js";
import ThreeDotsWave from "./ThreeDotsWave";
function TextMessages() {
  return (
    <BlocBuilder
      subject={chatBloc.subject}
      builder={(snapshot) =>
        snapshot.data === null ? (
          <div></div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column-reverse",
              position: "absolute",
              // bottom: 20,
              overflowY: "scroll",
              paddingLeft: 10,
              height: "100%",
              zIndex: "1",
              // direction: "column-reverse",
            }}
          >
            {chatBloc.isLoading ? <ThreeDotsWave /> : <div></div>}
            {MostRecent(snapshot.data)}
          </div>
        )
      }
    />
  );
}
function MostRecent(messages) {
  const maxShown = 30;
  const lastNMessages = messages.slice(
    messages.length - maxShown >= 0 ? messages.length - maxShown : 0
  );
  return lastNMessages
    .reverse()
    .map((item) => (
      <div style={stMessage(item.isUser)}>{item.messageText}</div>
    ));
}
function stMessage(isUser) {
  return {
    backgroundColor: isMobile()
      ? (isUser ? colors.primary : colors.black) + "95"
      : isUser
      ? colors.primary
      : colors.background,
    borderRadius: !isUser ? "10px 10px 10px 0" : "10px 10px 0 10px",
    color: isMobile() ? "white" : isUser ? "white" : "#50404D",
    fontWeight: 400,
    maxWidth: "70%",
    marginRight: 10,
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    alignSelf: !isUser ? "flex-start" : "flex-end",
  };
}

export default TextMessages;
