import { motion } from "framer-motion";
import React from "react";
import { colors } from "../styles";
const LoadingDot = {
    display: "block",
    width: "0.6rem",
    height: "0.6rem",
    backgroundColor: colors.background,
    borderRadius: "50%"
};

const LoadingContainer = {
    width: "3rem",
    height: "1rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "start"
};

const ContainerVariants = {
    initial: {
        transition: {
            staggerChildren: 0.2
        }
    },
    animate: {
        transition: {
            staggerChildren: 0.2
        }
    }
};

const DotVariants = {
    initial: {
        y: "0%"
    },
    animate: {
        y: "100%"
    }
};

const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: 'reverse',
    ease: "easeInOut"
};

export default function ThreeDotsWave() {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                paddingBottom: "10px",
                paddingRight: "15px",
            }}
        >
            <motion.div
                style={LoadingContainer}
                variants={ContainerVariants}
                initial="initial"
                animate="animate"
            >
                <motion.span
                    style={LoadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                />
                <motion.span
                    style={LoadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                />
                <motion.span
                    style={LoadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                />
            </motion.div>
        </div>
    );
}
