import React, { useState } from "react";
import sendIcon from "../images/sendIcon.svg";
import { colors } from "../styles.js";
import { chatBloc } from "../global.js";
import BlocBuilder from "./BlocBuilder";
import { audioBloc } from "../global.js";
import { isMobile } from "../util";
const inputFieldHeight = "50px";

function TextInput() {
  const [text, setText] = useState("");
  const [hoveringOverSend, setIsHoveringOverSend] = useState(false);
  return (
    <div>
      <BlocBuilder
        subject={chatBloc.subject}
        builder={(snapshot) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!chatBloc.isLoading && text != "") {
                chatBloc.sendMessage(text);
                setText("");
              }
            }}
          >
            <div style={{ display: "flex", marginTop: isMobile() ? 0 : 20 }}>
              <input
                type="text"
                id="input"
                placeholder="Enter a message!"
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={inputStyle}
                onFocus={() => {
                  if (!audioBloc.clickedBefore) {
                    audioBloc.play();
                    audioBloc.clickedBefore = true;
                  }
                }}
              />

              <button
                type="submit"
                onMouseEnter={() => setIsHoveringOverSend(true)}
                onMouseLeave={() => setIsHoveringOverSend(false)}
                style={{
                  width: inputFieldHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderLeft: "none",
                  borderRadius: isMobile() ? 0 : "0 4px 4px 0",
                  height: inputFieldHeight,
                  backgroundColor:
                    hoveringOverSend || chatBloc.isLoading || text === ""
                      ? "#0097B8"
                      : colors.primary,
                }}
              >
                <img src={sendIcon} style={{ height: "25px" }} />
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

const inputStyle = {
  width: "100%",
  border: isMobile() ? "none" : "1px solid #FFE1F9",
  borderRadius: isMobile() ? 0 : "4px 0 0 4px",
  height: inputFieldHeight,
  paddingLeft: "20px",
  paddingRight: "20px",
  outline: "none",
  marginBottom: isMobile() ? 0 : 10,
  position: "relative",
};

export default TextInput;
