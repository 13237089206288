import "./App.css";

import dots from "./images/dots.svg";
import logo from "./images/logo.svg";
import { colors } from "./styles.js";
import TextInput from "./components/TextInput.js";
import Scene from "./components/Scene";
import React from "react";
import { breakPoints } from "./util.js";
import { isMobile } from "./util.js";
import AudioPlayer from "./components/AudioPlayer";
import KeyboardAwareContainer from "./components/KeyboardAwareContainer";

class App extends React.Component {
  constructor() {
    super();
  }
  render() {
    const imageUrlsToCache = [
      "/yuri/excited.webp",
      "/yuri/laughing.webp",
      "/yuri/shy.webp",
      "/yuri/surprised.webp",
      "/yuri/thinking.webp",
    ];
    return (
      <KeyboardAwareContainer
        builder={() => (
          <div style={background}>
            {/* precache images */}
            {imageUrlsToCache.map((e) => (
              <img src={require("./images" + e)} style={{ display: "none" }} />
            ))}
            {/* play audio */}

            <div style={dotsPattern}>
              <div style={header}>
                <img src={logo} style={{ width: isMobile() ? 100 : "130px" }} />
                {isMobile() ? (
                  <AudioPlayer color={colors.secondary} />
                ) : (
                  <div />
                )}
                <a href={"mailto:contacterobi@gmail.com"}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      textDecoration: "none !important",
                    }}
                  >
                    <button className="contactButton" style={contactButton}>
                      Request feature
                    </button>
                  </div>
                </a>
              </div>
              <div style={body}>
                <Scene />
                <TextInput />
              </div>
              {!isMobile() ? (
                <div style={shoutout}>
                  Yuri, the music, and the background image are from{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Doki_Doki_Literature_Club!"
                    style={{ textDecoration: "underline" }}
                  >
                    Doki Doki Litterature Club.
                  </a>
                </div>
              ) : (
                <div></div>
              )}{" "}
            </div>
          </div>
        )}
      />
    );
  }
}

const background = { backgroundColor: colors.background, height: "100%" };
const dotsPattern = {
  // backgroundImage: `url(${dots})`,
  backgroundSize: "auto",
  backgroundPosition: "top",
  backgroundRepeat: "repeat",
  height: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};
const header = {
  paddingTop: "3vh",
  paddingBottom: "3vh",
  paddingLeft: "5vw",
  paddingRight: "5vw",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  width: "100%",
};
const body = {
  paddingRight: isMobile() ? 0 : "10vw",
  paddingLeft: isMobile() ? 0 : "10vw",
  height: "100%",
  width: "100%",
  maxWidth: "2000px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
};
const contactButton = {
  backgroundColor: colors.secondaryLight,
  paddingRight: "30px",
  paddingLeft: "30px",
  marginLeft: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  marginBottom: "auto",
  marginTop: "auto",
  color: colors.secondary,
  fontSize: isMobile() ? "15px" : "20px",
  fontWeight: 700,
  borderRadius: "50px",
};

const shoutout = {
  marginBottom: 50,
  paddingLeft: 50,
  paddingRight: 50,
  textAlign: "center",
  color: "#B8B8B8",
};

export default App;
