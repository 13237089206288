export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const breakPoints = {
  mobile: 480,
};

export function isMobile() {
  return window.innerWidth < breakPoints.mobile;
}
export const poses = ["excited", "laughing", "shy", "surprised", "thinking"];
