import { useCallback, useEffect, useState } from "react";
import { usePreventScroll } from "react-aria";

export default function KeyboardAwareContainer(params) {
  usePreventScroll({ isDisabled: false });
  const getHeight = useCallback(
    () =>
      //   window.visualViewport ? window.visualViewport.height : window.innerHeight,
      window.visualViewport.height,
    []
  );
  const [height, setHeight] = useState(getHeight());

  useEffect(() => {
    const handleResize = (e) => {
      setHeight(getHeight());
      console.log(height);
    };

    window.addEventListener("resize", handleResize);
    // From the top of my head this used to be required for older browsers since
    // this didn't trigger a resize event. Keeping it in to be safe.
    window.addEventListener("orientationchange", handleResize);
    // This is needed on iOS to resize the viewport when the Virtual/OnScreen
    // Keyboard opens. This does not trigger any other event, or the standard
    // resize event.
    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, [getHeight]);

  return <div style={{ height: height }}>{params.builder()}</div>;
}
